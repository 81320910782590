// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("src/assets/images/title-background.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".title[data-v-35519991]{width:16.25rem;height:1.5rem;box-sizing:border-box;padding-left:.95833rem;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-repeat:no-repeat;background-position:50%;background-size:100% 100%;display:flex;align-items:center;justify-content:space-between}.title .title-text[data-v-35519991]{font-size:.83333rem;line-height:.95833rem;text-shadow:0 0 .29167rem rgba(75,180,229,.37),-2px 2px 8px rgba(5,28,55,.42);background:linear-gradient(180deg,#abe5ff,#fff 60%);font-weight:600;-webkit-background-clip:text;-webkit-text-fill-color:transparent}", ""]);
// Exports
module.exports = exports;
