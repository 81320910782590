import VueSocketIO from 'vue-socket.io';
import SocketIO from 'socket.io-client';

const socketInstance = 'https://bigscreen.lsnf.dasding.cn'; // 替换为你的socket.io服务器地址

export const socket = new VueSocketIO({
    debug: false,
    connection: socketInstance,
    options: {
        autoConnect: false, //默认关闭，创建后打开，组件内使用可直接打开，就不需要用io.open()
        transports: ["websocket"],
        query: {
            token: "0000000"
        }

    }
});

export default socket;
