// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("src/assets/images/jsc/top_border.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".header{height:4.16667rem;z-index:2}.header,.header-content-bg{position:absolute;left:0;top:0;width:100%}.header-content-bg{height:100%;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-size:100% 100%}.header-title-text{position:absolute;left:50%;top:46%;transform:translate3d(-50%,-50%,0);margin-top:-.83333rem;font-size:1.66667rem;font-weight:400;color:#fff;letter-spacing:.16667rem;font-family:ysbth;text-shadow:0 0 .08333rem hsla(0,0%,100%,.8),0 0 .75rem rgba(130,165,255,.54),0 4px 1px rgba(19,80,143,.66);background:linear-gradient(180deg,#fff,#fff 40%,#77baff);-webkit-background-clip:text;-webkit-text-fill-color:transparent}@-webkit-keyframes pulsate-fwd{0%{transform:scaleX(1)}30%{transform:scale3d(.8,1.25,1);opacity:1}40%{transform:scaleY(.75);opacity:.5}50%{transform:scale3d(.2,1.15,1);opacity:0}65%{transform:scaleY(.95);opacity:.5}75%{transform:scale3d(.8,1.05,1);opacity:1}to{transform:scaleX(1)}}@keyframes pulsate-fwd{0%{transform:scaleX(1)}30%{transform:scale3d(.8,1.25,1);opacity:1}40%{transform:scaleY(.75);opacity:.5}50%{transform:scale3d(.2,1.15,1);opacity:0}65%{transform:scaleY(.95);opacity:.5}75%{transform:scale3d(.8,1.05,1);opacity:1}to{transform:scaleX(1)}}@-webkit-keyframes pulsates{0%{left:34%}to{left:48%}}@keyframes pulsates{0%{left:34%}to{left:48%}}", ""]);
// Exports
module.exports = exports;
