<template>
  <div class="app">
    <router-view />
  </div>
</template>
<script>
import { getConfig_Api } from "@/api/index.js";
export default {
  methods: {
    async createSocket() {
      const { io } = this.$socket;
      io.open();
      io.on("connecting", () => {
        console.log("正在连接");
      });
      io.on("connect", () => {
        console.log("连接成功");
      });
      io.on("disconnect", () => {
        console.log("断开连接");
      });
      io.on("connect_failed", () => {
        console.log("连接失败");
      });
      io.on("error", () => {
        console.log("服务器错误发生");
      });
      io.on("reconnect_attempt", () => {});
      io.on("reconnecting", () => {});
    },
    async get_configs() {
      let res = await getConfig_Api("");
      this.$store.set('configs', res.data)
    },
  },
  created() {
    this.createSocket();
  },
  async mounted() {
    await this.get_configs();
  },
};
</script>
