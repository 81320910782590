<template>
  <div class="right-group">
    <slot />
  </div>
</template>

<script>
export default {}
</script>
<style lang="scss">
.right-group {
  position: relative;
  z-index:4;
  margin-right: px2vw(48);
  margin-top: px2vh(75);
  width: px2vw(450);
  height: px2vh(963);
  box-sizing: border-box;
   background-image: url("~src/assets/images/jsc/_right_bg.png");
  background-size: 100% 100%;
   pointer-events: auto;
}
</style>
