<template>
  <div class="header touch-enable">
    <i class="header-content-bg"></i>
    <span class="header-title-text">{{ commonHeadTitle }}</span>
    <slot />
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  components: {},
  computed: {
    commonHeadTitle() {
      return "缙云畅享现代化农事服务中心";
    },
  },
  mounted() {},
  methods: {},
};
</script>
<style lang="scss">
.header {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: px2vh(100);
  z-index: 2;
}

.header-content-bg {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-image: url("~src/assets/images/jsc/top_border.png");
  background-size: 100% 100%;
}

.header-title-text {
  position: absolute;
  left: 50%;
  top: 46%;
  transform: translate3d(-50%, -50%, 0);
  margin-top: px2vh(-20);
  font-size: px2vh(40);
  font-weight: 400;
  color: #ffffff;
  letter-spacing: px2vh(4);
  font-family: "ysbth";

  text-shadow: 0px 0px px2vh(2) rgba(255, 255, 255, 0.8),
    0px 0px px2vh(18) rgba(130, 165, 255, 0.54), 0px 4px 1px rgba(19, 80, 143, 0.66);
  background: linear-gradient(180deg, #ffffff 0%, #ffffff 40%, #77baff 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
@keyframes pulsate-fwd {
  0% {
    transform: scaleX(1);
  }
  30% {
    transform: scale3d(0.8, 1.25, 1);
    opacity: 1;
  }
  40% {
    transform: scaleY(0.75);
    opacity: 0.5;
  }
  50% {
    transform: scale3d(0.2, 1.15, 1);
    opacity: 0;
  }
  65% {
    transform: scaleY(0.95);
    opacity: 0.5;
  }
  75% {
    transform: scale3d(0.8, 1.05, 1);
    opacity: 1;
  }
  100% {
    transform: scaleX(1);
  }
}
@keyframes pulsates {
  0% {
    left: 34%;
  }
  100% {
    left: 48%;
  }
}
</style>
