export function _set_Arry(arr, index) {
    let new_arry = arr.reduce((acc, curVal) => {
        if (acc.length === 0 || acc[acc.length - 1].length === index) {
            acc.push([curVal]);
        } else {
            acc[acc.length - 1].push(curVal);
        }

        return acc;
    }, []);
    return new_arry;
}