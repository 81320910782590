// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("src/assets/images/jsc/left_border.png");
var ___CSS_LOADER_URL_IMPORT_1___ = require("src/assets/images/jsc/right_border.png");
var ___CSS_LOADER_URL_IMPORT_2___ = require("src/assets/images/jsc/bottom.png");
var ___CSS_LOADER_URL_IMPORT_3___ = require("@/assets/images/imap/mengban.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
// Module
exports.push([module.id, ".normal-layout{position:relative;width:100%;left:0;top:0;padding-bottom:1.75rem;background:linear-gradient(90deg,rgba(2,5,16,.77) 13%,rgba(2,5,16,0) 31%,transparent 51%,rgba(2,5,16,0) 71%,rgba(2,5,16,.77) 90%);opacity:1;pointer-events:none}.normal-layout .touch-enable{pointer-events:auto}.normal-layout .left_{left:.20833rem;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ")}.normal-layout .left_,.normal-layout .right_{position:absolute;top:0;width:6.875rem;height:100%;background-size:100% 100%;z-index:1}.normal-layout .right_{right:.20833rem;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ")}.normal-layout .bottom_{position:absolute;left:50%;bottom:0;transform:translate3d(-50%,0,0);width:63.5rem;height:1.79167rem;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ");background-size:100% 100%;z-index:1}.i_bg{position:absolute;width:100%;height:100%;background:url(" + ___CSS_LOADER_URL_REPLACEMENT_3___ + ");background-size:100% 100%;z-index:1}", ""]);
// Exports
module.exports = exports;
