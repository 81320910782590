<template>
  <div class="title touch-enable" @click="$emit('click')">
    <span class="title-text">{{title}}</span>
    <slot></slot>
  </div>
</template>

<script>
export default {
  props:{
    title:{
      type: String,
      default: ''
    }
  }
}
</script>

<style scoped lang="scss">
.title{
  width: px2vw(390);
  height: px2vh(36);
  box-sizing: border-box;
  padding-left:px2vw(23);
  @include background-image('~src/assets/images/title-background.png');
  display: flex;
  align-items: center;
  justify-content: space-between;

  .title-text{
    font-size: px2vh(20);
    line-height: px2vh(23);
    text-shadow: 0 0 px2vh(7) rgba(75,180,229,0.37), -2px 2px 8px rgba(5,28,55,0.42);
    background: linear-gradient(180deg, #ABE5FF 0%, #FFFFFF 60%);
    font-weight:600;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

}
</style>
