import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: "/lsnf",
  },

  {
    path: '/lsnf', //产业引用
    name: 'lsnf',
    component: () =>
      import(/* webpackChunkName: "modules/cyyy" */ "../views/lsnf/index.vue"),
  },
  {
     path: '/map', //产业引用
       name: 'map',
        component: () =>
          import( /* webpackChunkName: "modules/cyyy" */ "../views/lsnf/map.vue"),
  }
];

const router = new VueRouter({
  routes,
});

export default router;
